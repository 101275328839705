import React, { useState, useImperativeHandle, forwardRef } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls,Trophies,Token } from "../scripts/contractApi.js";

const MerkleProof = forwardRef(({ proof, onMerkleProofSet,deployedAddress }, ref) => {
  const [step, setStep] = useState(false);

  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));

  const handleButtonClick = async () => {
    try {
      const result = await Token.setMerkleProof(deployedAddress,proof);
      if(result){
        onMerkleProofSet();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Set new merkle proof</p>
      <p className="DeploymentStep-inputfield-title">Proof</p>
      <input type="text" className="DeploymentStep-input" disabled={true} value={proof}/>
      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default MerkleProof;
