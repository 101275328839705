import React, { useRef, useState } from "react";
import "./App.css";
import { connectWallet, Manager, Factory, Multicalls } from "./scripts/contractApi.js";
import { MerkleTree } from "./scripts/utils.js";
import DeployContract from "./components/DeployContract.js";
import RegisterAtManager from "./components/RegisterAtManager.js";
import MerkleRoot from "./components/MerkleRoot.js";
import keccak256 from "keccak256";
import MerkleProof from "./components/MerkleProof.js";
import ContractParams from "./components/ContractParams.js";
import MintTime from "./components/MintTime.js";
import AddConsumer from "./components/AddConsumer.js";
import ContractParamsAdvanced from "./components/ContractParamsAdvanced.js";
const versionString = "v1.0.2";
const coordinatorAddress = "0x50d47e4142598E3411aA864e08a44284e471AC6f";
const keyhash = "0x027f94ff1465b3525f9fc03e9ff7d6d2c0953482246dd6ae07570c45d6631414";
const subscriptionId = 138;

function setupWalletConnection() {
  connectWallet();
}
function createNewSeason() {
  Multicalls.createNewGainlingsContractAndAddToManager("Gainlings Season 3", "TGS", "Test the factory", coordinatorAddress, subscriptionId, keyhash, (result) => {
    console.log("Factory deployed: " + result);
    console.log(result);
  });
}
function getDeploymentInfos() {
  var info = Manager.getDeploymentInfo();
  console.log(info);
}
async function createNewMerkle() {
  try {
    let aggregatedResults = []; // Initialize an empty array to store all results
    let pageSize = 5; // Set the page size
    let currentPage = 0; // Start from page 0

    // Initial call
    var info = await Manager.getPagedDeployments(currentPage, pageSize);

    // While loop to continue fetching as long as the returned array has length > 0
    while (info.length > 0) {
      // Add the results to the aggregated array, flattening the array of arrays
      aggregatedResults = aggregatedResults.concat(info);

      // Check if the length of the returned array is less than the page size
      // If it is, it means we've reached the last page
      if (info.length < pageSize) {
        break; // Exit the loop
      }

      // Wait for 200 milliseconds
      await new Promise((resolve) => setTimeout(resolve, 200));

      // Increase the page number for the next call
      currentPage++;

      // Make the next call
      info = await Manager.getPagedDeployments(currentPage, pageSize);
    }

    // Extract addresses from aggregated deployments
    const addresses = aggregatedResults.map((deployment) => deployment[1]);
    console.log(addresses);
    var merkle = await MerkleTree.buildMerkleTreeAwaitableFromArray(addresses);
    return merkle; // Return the aggregated addresses
  } catch (error) {
    console.error("Error fetching paged deployments:", error);
  }
}

function App() {
  const [deployedAddress, setDeployedAddress] = useState("0x");
  const [merkleRoot, setMerkleRoot] = useState("");
  const [merkleProof, setMerkleProof] = useState("");
  const [testnet, setTestnet] = useState(true);

  console.log("Factory", versionString);
  const deploymentStepRef = useRef();
  const registerAtManagerRef = useRef();
  const merkleRootRef = useRef();
  const merkleProofRef = useRef();
  const contractParamsRef = useRef();
  const contractParamsAdvancedRef = useRef();
  const mintTimeRef = useRef();
  const addConsumerRef = useRef();

  const resetAll = () => {
    if (deploymentStepRef.current) {
      deploymentStepRef.current.reset();
    }
    if (registerAtManagerRef.current) {
      registerAtManagerRef.current.reset();
    }
    if (merkleRootRef.current) {
      merkleRootRef.current.reset();
    }
    if (merkleProofRef.current) {
      merkleProofRef.current.reset();
    }
    if (contractParamsRef.current) {
      contractParamsRef.current.reset();
    }
    if (mintTimeRef.current) {
      mintTimeRef.current.reset();
    }
    if (addConsumerRef.current) {
      addConsumerRef.current.reset();
    }
    setDeployedAddress("0x"); // Reset the address in App state as well
  };

  const handleAddressCreated = (newAddress) => {
    setDeployedAddress(newAddress);
    console.log(`Deploy to ${deployedAddress}`);
  };

  const handleRegisteredAtManager = async () => {
    let merkleTree = await createNewMerkle();
    let root = merkleTree.getHexRoot();
    let proof = merkleTree.getHexProof(keccak256(deployedAddress.toLowerCase()));
    console.log(`Merke root is ${root}`);
    console.log(`Merke proof is ${proof}`);
    setMerkleRoot(root);
    setMerkleProof(proof);
  };

  const handleMerkleRootSet = () => {};
  const handleMerkleProofSet = () => {};
  const handleContractParamsSet = () => {};
  const handleContractParamsAdvancedSet = () => {};
  const handleMintTimeSet = () => {};
  const handleVrfConsumerAdded = () => {};

  // Function to handle the checkbox change
  const handleTestnetToggle = () => {
    setTestnet((prevTestnet) => !prevTestnet);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="headerBox">
          <p>Connect Wallet</p>
          <button className="Button" role="button" onClick={setupWalletConnection}>
            Connect Wallet
          </button>
          <button className="Button" role="button" onClick={resetAll}>
            Reset All Fields
          </button>

          <div className="labelWithToggle">
            <p>Testnet</p> {/* Label Text */}
            <label className="toggle">
              <input type="checkbox" checked={testnet} onChange={handleTestnetToggle} />
              <span className="slider"></span>
            </label>
          </div>
        </div>

        <DeployContract ref={deploymentStepRef} onAddressCreated={handleAddressCreated} testnet={testnet} />
        <RegisterAtManager ref={registerAtManagerRef} address={deployedAddress} registeredAtManager={handleRegisteredAtManager} testnet={testnet} />
        <MerkleRoot ref={merkleRootRef} onMerkleRootSet={handleMerkleRootSet} root={merkleRoot} testnet={testnet} />
        <MerkleProof ref={merkleProofRef} onMerkleProofSet={handleMerkleProofSet} proof={merkleProof} deployedAddress={deployedAddress} testnet={testnet} />
        <ContractParams ref={contractParamsRef} onContractParamsSet={handleContractParamsSet} deployedAddress={deployedAddress} testnet={testnet} />
        <ContractParamsAdvanced ref={contractParamsAdvancedRef} onContractParamsSet={handleContractParamsAdvancedSet} deployedAddress={deployedAddress} testnet={testnet} />
        <MintTime ref={mintTimeRef} onMintTimeSet={handleMintTimeSet} deployedAddress={deployedAddress} testnet={testnet} />
        <AddConsumer ref={addConsumerRef} onConsumerAdded={handleVrfConsumerAdded} deployedAddress={deployedAddress} testnet={testnet} />
      </header>
    </div>
  );
}

export default App;
