import React, { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import "../styles/DeploymentStep.css"; // Import the CSS file
import "../App.css";
import { connectWallet, Manager, Factory, Multicalls, Trophies, Token } from "../scripts/contractApi.js";
import { defaults } from "../constants/defaultValues.js";
import { ethers } from "ethers";

const ContractParams = forwardRef(({ onContractParamsSet, deployedAddress, testnet }, ref) => {
  const [step, setStep] = useState(false);
  const [gainlingsStorageContractAddress, setGainlingsStorageContractAddress] = useState(defaults(testnet).STORAGE_CONTRACT_ADDRESS);
  const [libraryContractAddress, setLibraryContractAddress] = useState(defaults(testnet).LIBRARY_CONTRACT_ADDRESS);
  const [trophyFactoryContractAddress, setTrophyFactoryContractAddress] = useState(defaults(testnet).TROPHY_CONTRACT_ADDRESS);
  const [marketContractAddress, setMarketContractAddress] = useState(defaults(testnet).MARKET_CONTRACT_ADDRESS);
  const [animationUrl, setAnimationUrl] = useState(defaults(testnet).ANIMATION_URL);
  const [pricePerToken, setPricePerToken] = useState(defaults(testnet).PRICE_PER_TOKEN);
  const [mintPriceString, setMintPriceString] = useState(`${ethers.utils.formatEther(defaults(testnet).PRICE_PER_TOKEN)} ETH`);



  useEffect(() => {
    setGainlingsStorageContractAddress(defaults(testnet).STORAGE_CONTRACT_ADDRESS);
    setLibraryContractAddress(defaults(testnet).LIBRARY_CONTRACT_ADDRESS);
    setTrophyFactoryContractAddress(defaults(testnet).TROPHY_CONTRACT_ADDRESS);
    setMarketContractAddress(defaults(testnet).MARKET_CONTRACT_ADDRESS);
    setAnimationUrl(defaults(testnet).ANIMATION_URL);
    setPricePerToken(defaults(testnet).PRICE_PER_TOKEN);
    setMintPriceString(`${ethers.utils.formatEther(defaults(testnet).PRICE_PER_TOKEN)} ETH`);
  }, [testnet]);

  
  useImperativeHandle(ref, () => ({
    reset() {
      setStep(false);
    },
  }));

  const handleGainlingsStorageContractAddressChange = (e) => {
    setGainlingsStorageContractAddress(e.target.value);
  };
  const handleLibraryContractAddressChange = (e) => {
    setLibraryContractAddress(e.target.value);
  };
  const handleTrophyFactoryContractAddressChange = (e) => {
    setTrophyFactoryContractAddress(e.target.value);
  };
  const handleMarketContractAddressChange = (e) => {
    setMarketContractAddress(e.target.value);
  };
  const handleAnimationUrlChange = (e) => {
    setAnimationUrl(e.target.value);
  };
  const handlePricePerTokenChange = (e) => {
    const inEth = ethers.utils.formatEther(e.target.value);
    setMintPriceString(`${inEth} ETH`);
    setPricePerToken(e.target.value);
  };

  const handleButtonClick = async () => {
    try {
      const result = await Token.setContractParams(
        deployedAddress,
        gainlingsStorageContractAddress,
        libraryContractAddress,
        trophyFactoryContractAddress,
        marketContractAddress,
        animationUrl,
        pricePerToken
      );
      if (result) {
        onContractParamsSet();
        setStep(true);
      }
    } catch (error) {}
  };

  return (
    <div className="DeploymentStep-container">
      <p className="DeploymentStep-title">Set Contract Params</p>

      <p className="DeploymentStep-inputfield-title">Gainlings Storage Contract Address</p>
      <input type="text" className="DeploymentStep-input" value={gainlingsStorageContractAddress} onChange={handleGainlingsStorageContractAddressChange} />

      <p className="DeploymentStep-inputfield-title">Gainlings Library Contract Address</p>
      <input type="text" className="DeploymentStep-input" value={libraryContractAddress} onChange={handleLibraryContractAddressChange} />

      <p className="DeploymentStep-inputfield-title">Trophy Factory Contract Address</p>
      <input type="text" className="DeploymentStep-input" value={trophyFactoryContractAddress} onChange={handleTrophyFactoryContractAddressChange} />

      <p className="DeploymentStep-inputfield-title">Market Contract Address</p>
      <input type="text" className="DeploymentStep-input" value={marketContractAddress} onChange={handleMarketContractAddressChange} />

      <p className="DeploymentStep-inputfield-title">Animation Url</p>
      <input type="text" className="DeploymentStep-input" value={animationUrl} onChange={handleAnimationUrlChange} />

      <p className="DeploymentStep-inputfield-title">Price Per Gainlings</p>
      <input type="text" className="DeploymentStep-input" value={pricePerToken} onChange={handlePricePerTokenChange} />
      {mintPriceString && (
        <p className="DeploymentStep-inputfield-title">
          {mintPriceString}
        </p>
      )}
      <button className="StepButton" onClick={handleButtonClick}>
        {`Send tx ${step ? "✅" : ""}`}
      </button>
    </div>
  );
});

export default ContractParams;
