export const defaultsTestnet = {
    //COMMON
    
    //TESTNET
    NAME: "The Gainlings Season 3",
    TAG: "TGS",
    VRF_COORDINATOR_ADDRESS: "0x50d47e4142598E3411aA864e08a44284e471AC6f",
    KEY_HASH: "0x027f94ff1465b3525f9fc03e9ff7d6d2c0953482246dd6ae07570c45d6631414",
    SUBSCRIPTION_ID: "138",
    //
    TROPHY_CONTRACT_ADDRESS: "0x7c71bdC39FEbE25684ACfDE7fFaa9BAf20b91A35", 

    STORAGE_CONTRACT_ADDRESS: "0xe48f16f3d5C3dB9B769143fe6C6960B073C337f0", 
    LIBRARY_CONTRACT_ADDRESS: "0x84260733129c580181B2f6eD812F5C322a3Ad250", 
    MARKET_CONTRACT_ADDRESS: "0x7F42322Fb6F580136952fE1bF3438a9694B05a29", 
    ANIMATION_URL: "https://hidden-frog-6004.on.fleek.co/",
    PRICE_PER_TOKEN: "1000000000000000",

    MINT_LENGTH: "1800", 
    PREP_LENGTH: "300", 
}

export const defaultsMainnet = {
    //COMMON
    
    //TESTNET
    NAME: "The Gainlings Season 3",
    TAG: "TGS",
    VRF_COORDINATOR_ADDRESS: "0x50d47e4142598E3411aA864e08a44284e471AC6f",
    KEY_HASH: "0x027f94ff1465b3525f9fc03e9ff7d6d2c0953482246dd6ae07570c45d6631414",
    SUBSCRIPTION_ID: "31",
    //
    TROPHY_CONTRACT_ADDRESS: "0x7c71bdC39FEbE25684ACfDE7fFaa9BAf20b91A35", 

    STORAGE_CONTRACT_ADDRESS: "0xe48f16f3d5C3dB9B769143fe6C6960B073C337f0", 
    LIBRARY_CONTRACT_ADDRESS: "0x84260733129c580181B2f6eD812F5C322a3Ad250", 
    MARKET_CONTRACT_ADDRESS: "0x7F42322Fb6F580136952fE1bF3438a9694B05a29", 
    ANIMATION_URL: "https://hidden-frog-6004.on.fleek.co/",
    PRICE_PER_TOKEN: "1000000000000000",

    MINT_LENGTH: "1800", 
    PREP_LENGTH: "300", 
}

export const defaults = (mainnet) => {
    if(mainnet){
        return defaultsTestnet;
    }
    else{
        return defaultsMainnet;
    }
}